import React from "react";
import { PaymentCenterService } from "../../../../_services/payment-center.service";
import { SharedService } from "../../../../_services/shared.service";
import { getCardYears, convertStringToAmount } from "../../../../_services/utility.service";
import { ARTICLES, MONTHS_LONG } from "../../../../_constants/constants";
import { ChangeFrequencyAutopay } from "../../../shared/modals/change-frequency-autopay/ChangeFrequencyAutopay";
import { UpdatePaymentMethods } from "../../../shared/modals/update-payment-method/UpdatePaymentMethod";
import { ConfirmBox } from "../../../shared/modals/confirm-box/ConfirmBox";
import { Notification } from "../../../shared/notifications/notifications";
import { ProductsMobile } from "./mobile/ProductsMobile";
import { ProductsDesktop } from "./desktop/ProductsDesktop";
import "./Products.scss";

export class Products extends React.Component {
  paymentCenterService = new PaymentCenterService();
  sharedService = new SharedService();
  changeFrequencyAutoPayRef = React.createRef();

  state = {
    products: [],
    paymentMethods: [],
    hasAnyPaymentMethods: false,
    years: getCardYears(),
    states: [],
    clientName: '',
    paymentInfo: null,

    selectedProduct: undefined,
    selectedPaymentMethod: null,

    globlWebContents: {},

    confirmPopup: {
      title: 'This is title',
      content: 'This is content',
      positiveBtnText: 'I Agree',
      negativeBtnText: 'I Do Not Agree'
    },

    showChangeFrequencyAutopay: false,
    showPaymentMethodPop: false,
    showConfirmBox: false
  };

  componentDidMount() {
    this.getPaymentMethods();
    this.getProducts();
    this.globalWC_getContent([
      ARTICLES.CLAIM_NOW,
      ARTICLES.PRODUCT_DISCLAIMER,
      ARTICLES.PRODUCTS_NO_RADIO,
      ARTICLES.PRODUCTS_AUTO_TOOLTIP,
      ARTICLES.REMOVE_AUTOPAY_CONFIRM,
      ARTICLES.PRODUCT_DISCLAIMER_COMBINED_BILLING,
      ARTICLES.PRODUCT_DISCLAIMER_NOCOST,
      ARTICLES.SITE_DISCLAIMER,
      ARTICLES.AUTOPAY_INSURANCE,
      ARTICLES.AUTOPAY_NON_INSURANCE,
      ARTICLES.AUTOPAY_INSURANCE_NOBILL,
      ARTICLES.FREQUENCY_UPDATED,
      ARTICLES.FREQUENCY_UPDATED_NOBILL,
      ARTICLES.AUTOPAY_MARKETTING,
      ARTICLES.TRANSACTION_DISCLAIMER,
      ARTICLES.TOOLTIP_DUE_FREQUENCY,
      ARTICLES.TOOLTIP_FREQUENCY_NO_SELECTION
    ]);
  }

  getPaymentMethods = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let { companyId, client } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      client: client,
      accountNumber: user.accountNumber,
      policyNumber: user.policyNumber,
      firstName: user.firstName,
      lastName: user.lastName,
      userid: user.userid,
      isEmailSubscribed: user?.isEmailSubscribed,
      companyId: companyId
    };

    this.props.setLoading(true);
    this.paymentCenterService.getPaymentMethods(params).subscribe(response => {
      let paymentMethods = [];
      if (response.status === 200) {
        paymentMethods = response.data.paymentMethods || [];
      }

      this.setState(
        {
          paymentMethods: paymentMethods,
          hasAnyPaymentMethods: response.status === 200 && response.data.paymentMethods && response.data.paymentMethods.length !== 0 ? true : false
        }, () => {
          // If payment method is already selected, then we need to add 'remove auto pay' for that product if applicable
          if (this.state.selectedProduct) {
            this.setState({
              paymentMethods: this.getModifiedPaymentMethod(this.state.selectedProduct)
            })
          }
        }
      );
    });
  };

  globalWC_getContent = titles => {
    let { globalGroupId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      globalGroupId: globalGroupId,
      domainGroupId: domainGroupId,
      titles: titles
    };

    this.sharedService.getContents(params).subscribe(response => {
      if (response.status === 200) {
        let webContents = {};
        response.data.items && response.data.items.forEach(element => {
          webContents[element.title] = element.content;
        });
        this.setState({
          globlWebContents: webContents
        });
      }
    });
  };

  handleChangeFrequencyAutopayClose = (data, extra) => {
    if (data === "ADD_NEW_PAYMENT_METHOD") {
      this.setState({ showPaymentMethodPop: true, showChangeFrequencyAutopay: false });
    }
    else if (data) {
      let type = this.changeFrequencyAutoPayRef.current.getEmailType(extra, this.state.selectedProduct);
      this.setState({ confirmPopup: this.getConfirmPopupData(type, extra) }, () => {
        this.setState({ showConfirmBox: true, changeFrequencyParam: extra, showChangeFrequencyAutopay: false });
      })
    } else {
      this.setState({ showChangeFrequencyAutopay: false })
    }

  };

  handlePaymentMethodClose = (data, messages) => {
    if (data) {
      this.props.notificationContext.setPopupNotifications(messages)
      this.getProducts();
      // this.getPaymentMethods();
      let paymentMethods = data;

      this.setState({
        paymentMethods: paymentMethods,
        hasAnyPaymentMethods: paymentMethods && paymentMethods.length !== 0 ? true : false
      }, () => {
        if (this.state.selectedProduct) {
          this.setState({
            selectedPaymentMethod: paymentMethods[0].paymentRecordId, // select the new payment method as selected
            paymentMethods: this.getModifiedPaymentMethod(this.state.selectedProduct), // If payment method is already selected, then we need to add 'remove auto pay' for that product if applicable
          }, () => {
            this.setState({ showPaymentMethodPop: false, showChangeFrequencyAutopay: true });
          })
        }
      }
      );
    } else {
      this.setState({ showPaymentMethodPop: false, showChangeFrequencyAutopay: true });
    }
  };

  handleConfirmBoxClose = (data) => {
    this.setState({ showConfirmBox: false });
    if (data) {
      this.submitChangeFrequency(this.state.changeFrequencyParam, () => { })
    } else {
      this.setState({ showChangeFrequencyAutopay: true });
    }
  };

  getProducts = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let { companyId, client } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      client: client,
      accountNumber: user.accountNumber,
      policyNumber: user.policyNumber,
      firstName: user.firstName,
      lastName: user.lastName,
      userid: user.userid,
      isEmailSubscribed: user?.isEmailSubscribed,
      companyId: companyId
    };

    this.props.setLoading(true);
    this.paymentCenterService.getProducts(params).subscribe(response => {
      this.props.setLoading(false);
      if (response.status === 200) {
        this.setState({
          products: response.data.activeProducts || [],
          clientName: response.data.clientName
        });
      } else {
        this.setState({
          paymentMethods: [],
          products: [],
          selectedProduct: undefined
        });
      }

      this.props.notificationContext.processMessages(response.data.messages);
    });
  };

  radioBtnChange = selectedProduct => {
    this.setState({
      selectedProduct: selectedProduct,
      selectedPaymentMethod: this.identifySelectedPaymentMethod(selectedProduct)
    });
  };

  handlePayNow = selectedProduct => {
    this.props.history.push({
      pathname: "/payment-center/pay-now",
      data: {
        selectedProduct: selectedProduct || this.state.selectedProduct,
        clientName: this.state.clientName,
        fromScreen: 'PRODUCTS'
      }
    });
  };

  handleChangeFreqAutoPay = selectedProduct => {

    this.getUpdatedFrenquecyData(selectedProduct.policyNumber, (response) => {
      if (response.status === 200) {
        this.setState({
          selectedProduct: { ...selectedProduct, ...response.data.activeProducts[0] },
          paymentMethods: this.getModifiedPaymentMethod(response.data.activeProducts[0]),
          showChangeFrequencyAutopay: true,
          selectedPaymentMethod: this.identifySelectedPaymentMethod(response.data.activeProducts[0])
        });
      } else {
        this.props.notificationContext.processMessages(response.data.messages)
      }
    })


  };

  getUpdatedFrenquecyData = (policyNumber, callback) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let { client } = JSON.parse(localStorage.getItem("initialIds"));

    let params = {
      "client": client,
      accountNumber: user.accountNumber,
      policyNumber: policyNumber,
      firstName: user.firstName,
      lastName: user.lastName,
      userid: user.userid,
      isEmailSubscribed: user?.isEmailSubscribed,
      "csUserid": ""
    }

    this.props.setLoading(true);
    this.paymentCenterService.getUpdatedFrequency(params).subscribe(response => {
      this.props.setLoading(false);
      callback(response)
    });
  }

  getModifiedPaymentMethod = (selectedProduct) => {
    let filteredPaymentMethods = this.state.paymentMethods;
    let enableOptions = selectedProduct.paymentAction.saveChanges.disable === 'N' ? true : false;

    // Determin whether to add 'Remove Auto Pay' or 'Not on Auto Pay' 
    if (selectedProduct.paymentStatusDisplayStyle && selectedProduct.paymentStatusDisplayStyle === 'autopay') {
      const found = this.state.paymentMethods.some(paymentMethod => paymentMethod.paymentMethodHeader === "Remove Auto Pay");
      filteredPaymentMethods = filteredPaymentMethods.filter(paymentMethod => paymentMethod.paymentMethodHeader !== "Not on Auto Pay")
      if (!found && enableOptions) {
        filteredPaymentMethods.unshift({
          paymentRecordId: "00",
          paymentMethodHeader: "Remove Auto Pay"
        });
      }
    } else {
      filteredPaymentMethods = filteredPaymentMethods.filter(paymentMethod => paymentMethod.paymentMethodHeader !== "Remove Auto Pay")
      const found = this.state.paymentMethods.some(paymentMethod => paymentMethod.paymentMethodHeader === "Not on Auto Pay");
      if (!found) {
        filteredPaymentMethods.unshift({
          paymentRecordId: "00",
          paymentMethodHeader: "Not on Auto Pay"
        });
      }
    }

    // Add 'Add new payment method'
    const found = this.state.paymentMethods.some(paymentMethod => paymentMethod.paymentRecordId === "ADD_NEW_PAYMENT_METHOD");
    if (!found) {
      filteredPaymentMethods.push({
        paymentRecordId: "ADD_NEW_PAYMENT_METHOD",
        paymentMethodHeader: "Add new payment method"
      });
    }

    return filteredPaymentMethods
  }

  identifySelectedPaymentMethod = (selectedProduct) => {
    let notOnAutoPay = selectedProduct.paymentStatusDisplayStyle !== 'autopay';

    if (notOnAutoPay) {
      return '00'
    }
    else if (!notOnAutoPay && this.state.hasAnyPaymentMethods) {
      return selectedProduct.currentPaymentMethodRecordId
    }
    else {
      return 'ADD_NEW_PAYMENT_METHOD'
    }
  }

  submitChangeFrequency = (params, callback) => {
    this.props.setLoading(true);
    this.paymentCenterService.changeFrequencyAutopay(params).subscribe(response => {
      this.props.setLoading(false);
      if (response.status === 200) {
        this.props.notificationContext.processMessages(response.data.messages);
        this.setState({
          products: response.data.activeProducts,
          selectedProduct: undefined
        });
      }

      callback(response);
    });
  }

  getConfirmPopupData = (type, params) => {
    if (type === 'add' || type === 'both' || type === 'payment-method' || type === 'add-admin-fees') {
      return {
        title: 'Payment Authorization',
        content: this.getAttestationContent(params),
        positiveBtnText: 'I Agree',
        negativeBtnText: 'I Do Not Agree'
      }
    }

    if (type === 'frequency') {
      return {
        title: 'Change Auto Pay or Frequency',
        content: this.getAttestationContentForFrequency(params),
        positiveBtnText: 'I Agree',
        negativeBtnText: 'Cancel Request'
      }
    }

    if (type === 'remove') {
      return {
        title: 'Confirm Remove Auto Pay',
        content: this.state.globlWebContents[ARTICLES.REMOVE_AUTOPAY_CONFIRM],
        positiveBtnText: 'I Agree',
        negativeBtnText: 'I Do Not Agree'
      }
    }
  }

  getAttestationContentForFrequency = (params) => {
    let product = this.state.selectedProduct;
    let isBillingFeeInd = product.billingFeeInd === 'Y';
    let content = isBillingFeeInd ? this.state.globlWebContents[ARTICLES.FREQUENCY_UPDATED] : this.state.globlWebContents[ARTICLES.FREQUENCY_UPDATED_NOBILL];

    let paymentFreqObj = product.availablePaymentFrequencyList
      .find(item => item.code === params.paymentDetails[0].billingMode);

    let dataMap = [
      { field: '[$FREQUENCY$]', value: `${paymentFreqObj.value1}` },
      { field: '[$OLD_FREQUENCY$]', value: `${product.currentPaymentFrequecyType}` },
      { field: '[$AMOUNT_DUE$]', value: `${paymentFreqObj.value2}` },
    ];

    dataMap.forEach((item) => {
      content = content.replaceAll(item.field, item.value);
    })

    return content;
  }

  getAttestationContent = (params) => {
    let content = '';
    let product = this.state.selectedProduct;
    let isInsuranceProduct = this.state.selectedProduct.isInsuranceProduct === 'Y';
    let isBillingFeeInd = product.billingFeeInd === 'Y';

    let dataMap, _dataMap;

    let paymentMethod = this.state.paymentMethods
      .find(item => item.paymentRecordId === params.paymentDetails[0].paymentMethodRecordId)

    let paymentMethodType = paymentMethod.creditCard ? 'creditCard' : 'bankAccount' // credit card or bank account!

    let user = JSON.parse(localStorage.getItem("user"));

    let paymentFreqObj = product.availablePaymentFrequencyList
      .find(item => item.code === params.paymentDetails[0].billingMode);

    let { clientPhoneNumber } = JSON.parse(localStorage.getItem("initialIds"));

    // Determine the type of web content
    if (isInsuranceProduct) {
      content = isBillingFeeInd ? this.state.globlWebContents[ARTICLES.AUTOPAY_INSURANCE] : this.state.globlWebContents[ARTICLES.AUTOPAY_INSURANCE_NOBILL];
    } else {
      content = this.state.globlWebContents[ARTICLES.AUTOPAY_NON_INSURANCE];
    }

    // Determin financial institution
    let accountStr = paymentMethod.creditCard ? '' : ' account';
    let financialInstitutionName = paymentMethod[paymentMethodType] ? (paymentMethod[paymentMethodType].financialInstitutionName + accountStr) : '';

    // Define the common varaibles
    dataMap = [
      { field: '[$CURRENT_DATE$]', value: `${MONTHS_LONG[new Date().getMonth()]} ${new Date().getDate()}, ${new Date().getFullYear()}` }, // Month DD, YYYY
      { field: '[$FULL_NAME$]', value: `${user.firstName} ${user.lastName}` },
      { field: '[$PRODUCT_NAME$]', value: `${product.productDesc}` },
      { field: '[$CLIENT_NAME$]', value: this.state.clientName },
      { field: '[$TOLL_FREE$]', value: clientPhoneNumber },
      { field: '[$USER_NAME$]', value: `${user.firstName} ${user.lastName}` },
      { field: '[$DUE_DATE$]', value: `${product.dueDate}` },
      { field: '[$FREQUENCY$]', value: `${paymentFreqObj.value1}` },
      { field: '[$OLD_FREQUENCY$]', value: `${product.currentPaymentFrequecyType}` },
      { field: '[$FIN_INSTITUTION$]', value: `${financialInstitutionName}` },
      { field: '[$ACCOUNT_NUM$]', value: `${paymentMethod[paymentMethodType] && paymentMethod[paymentMethodType].accountNumberLast4Digit}` },
    ];

    // Define the dynamic and unique variables
    if (isInsuranceProduct && isBillingFeeInd) {
      let billingFeeObj = product.billingFees.find((item) => item.code === paymentMethod.billingMethodCode);
      let premium = convertStringToAmount(paymentFreqObj.value2);
      let billFee = convertStringToAmount(billingFeeObj.value2);

      _dataMap = [
        { field: '[$AMOUNT_DUE$]', value: `$${(premium + billFee).toFixed(2)}` },
        { field: '[$PREMIUM$]', value: `${paymentFreqObj.value2}` },
        { field: '[$BILLING_FEE$]', value: `${billingFeeObj.value2}` },
      ]
    } else {
      _dataMap = [
        { field: '[$AMOUNT_DUE$]', value: `${paymentFreqObj.value2}` },
      ]
    }

    dataMap = dataMap.concat(_dataMap)
    dataMap.forEach((item) => {
      content = content.replaceAll(item.field, item.value);
    })

    return content;
  }

  getPaymentInfo = (policyNumber) => {
    return new Promise((res, rej) => {
      let { client } = JSON.parse(localStorage.getItem("initialIds"));
      let user = JSON.parse(localStorage.getItem("user"));

      let params = {
        "client": client,
        "accountNumber": user.accountNumber,
        "policyNumber": policyNumber,
        "firstName": user.firstName,
        "lastName": user.lastName,
        "userid": user.userid,
        "csUserid": ""
      }

      this.props.setLoading(true)
      this.paymentCenterService.getPaymentInfo(params).subscribe((response) => {
        this.props.setLoading(false)
        if (response.status === 200) {
          let products = this.state.products;
          let selectedProduct = products.find((product) => product.policyNumber === policyNumber)
          selectedProduct['paymentInfo'] = response.data.paymentInfo;
          selectedProduct['paymentInfoLoaded'] = true;

          this.setState({
            products: products
          })

          res()
        } else {
          rej()
        }
      })
    })

  }


  render() {
    return (
      <>
        {this.state.showChangeFrequencyAutopay && this.state.selectedProduct && (
          <ChangeFrequencyAutopay
            //
            ref={this.changeFrequencyAutoPayRef}
            product={this.state.selectedProduct}
            paymentMethods={this.state.paymentMethods}
            selectedPaymentFrequecy={this.state.selectedProduct.currentPaymentFrequecyCode}
            selectedPaymentMethod={this.state.selectedPaymentMethod}
            show={this.state.showChangeFrequencyAutopay}
            hide={this.handleChangeFrequencyAutopayClose}
            submitChangeFrequency={this.submitChangeFrequency}
            {...this.props}
          />
        )}
        {this.state.showPaymentMethodPop && (
          <UpdatePaymentMethods
            states={this.state.states}
            years={this.state.years}
            selectedPaymentMethod={null}
            show={this.state.showPaymentMethodPop}
            hide={this.handlePaymentMethodClose}
            {...this.props}
          />
        )}
        {this.state.showConfirmBox && (
          <ConfirmBox
            show={this.state.showConfirmBox}
            hide={this.handleConfirmBoxClose}
            title={this.state.confirmPopup.title}
            content={this.state.confirmPopup.content}
            positiveBtnText={this.state.confirmPopup.positiveBtnText}
            negativeBtnText={this.state.confirmPopup.negativeBtnText}
            {...this.props}
          />
        )}
        <div>
          {this.props.notificationContext.state.nonInfoNotificaiton.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
          {this.state.products.length !== 0 && (
            <>
              {this.props.globalContext.state.deviceWidth >= 768 && (
                <ProductsDesktop
                  //
                  products={this.state.products}
                  radioBtnChange={this.radioBtnChange}
                  selectedProduct={this.state.selectedProduct}
                  handleChangeFreqAutoPay={this.handleChangeFreqAutoPay}
                  handlePayNow={this.handlePayNow}
                  globlWebContents={this.state.globlWebContents}
                  getPaymentInfo={this.getPaymentInfo}
                />
              )}

              {this.props.globalContext.state.deviceWidth <= 767 && (
                <ProductsMobile
                  //
                  products={this.state.products}
                  handleChangeFreqAutoPay={this.handleChangeFreqAutoPay}
                  handlePayNow={this.handlePayNow}
                  globlWebContents={this.state.globlWebContents}
                  getPaymentInfo={this.getPaymentInfo}
                />
              )}
            </>
          )}

        </div>
      </>
    );
  }
}
