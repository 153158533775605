import React from "react";
import { ProductsService } from "../../../../_services/products.service";
import { SharedService } from "../../../../_services/shared.service";
import { Notification } from "../../../../_components/shared/notifications/notifications";
import "./ProductDetails.scss";
import { ProductDetailsDesktop } from "./desktop/ProductDetailsDesktop";
import { ProductDetailsMobile } from "./mobile/ProductDetailsMobile";
import { ARTICLES, PAGE_TITLES, PAGES_TITLE_KEYS, VIEW_DOC_LOADER_HTML_STR } from '../../../../_constants/constants'
import { Helmet } from "react-helmet";

export class ProductDetails extends React.Component {
  productsService = new ProductsService();
  sharedService = new SharedService();
  windowRef = null;

  state = {
    productOverview: null,
    additionalInsured: null,
    optionalRiders: [],
    productDocuments: [],
    correspondences: [],

    globlWebContents: {},

    dataLoaded: false,
    pageTitle: ""
  };

  componentDidMount() {

    let { client, clientName } = JSON.parse(localStorage.getItem("initialIds"));
    this.setState({ pageTitle: clientName + PAGE_TITLES.PRODUCT_DETAILS })

    this.getProductDetails();

    this.globalWC_getContent([
      ARTICLES.PRODUCT_DETAILS_BENEFITS,
      ARTICLES.PRODUCT_DETAILS_MISMATCH_DATA,
      ARTICLES.SITE_DISCLAIMER,
      ARTICLES.DOCUMENT_ERROR
    ])
  }

  globalWC_getContent = titles => {
    let { globalGroupId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      globalGroupId: globalGroupId,
      domainGroupId: domainGroupId,
      titles: titles
    };

    this.sharedService.getContents(params).subscribe(response => {
      if (response.status === 200) {
        let webContents = {};
        response.data.items && response.data.items.forEach(element => {
          webContents[element.title] = element.content;
        });
        this.setState({
          globlWebContents: webContents
        });
      }
    });
  };

  getProductDetails = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let { companyId, client } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      client: client,
      accountNumber: user.accountNumber,
      policyNumber: this.props.match.params.id,
      firstName: user.firstName,
      lastName: user.lastName,
      userid: user.userid,
      isEmailSubscribed: user?.isEmailSubscribed,
      companyId: companyId
    };

    this.props.setLoading(true);
    this.productsService.getProductDetails(params).subscribe(response => {
      this.props.setLoading(false);
      if (response.status === 200) {
        this.setState({
          productOverview: response.data.productDetail,
          additionalInsured: response.data.additionalInsured,
          optionalRiders: response.data.optionalRiders || [],
          productDocuments: response.data.productDocuments || [],
          correspondences: response.data.correspondences || [],
          // message: response.data.messages,
          dataLoaded: true
        });
      } else {
        this.setState({
          optionalRiders: [],
        });
      }
      this.props.notificationContext.processMessages(response.data.messages);
    });
  };

  convertToPDF = (response) => {
    let blob = this.converBase64toBlob(response, 'application/pdf');
    let blobURL;
    let isMoibile = this.props.globalContext.state.deviceWidth <= 767;
    if (navigator.appVersion.toString().indexOf('.NET') > 0)
      window.navigator.msSaveOrOpenBlob(blob, 'Document.pdf');
    else {
      blobURL = URL.createObjectURL(blob);
      this.windowRef.location = blobURL;
      isMoibile && setTimeout(() => {
        this.windowRef.close();
      }, 1000)
    }
  };

  converBase64toBlob = (content, contentType) => {
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = window.atob(content); //method which converts base64 to binary
    var byteArrays = [
    ];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
      type: contentType || 'application/octet-stream'
    }); //statement which creates the blob
    return blob;
  };

  viewDocument = (correspondence, docList) => {
    let isIEBrowser = navigator.appVersion.toString().indexOf('.NET') > 0
    if (!isIEBrowser) {
      this.windowRef = window.open();
      this.windowRef.document.write(VIEW_DOC_LOADER_HTML_STR);
    }

    this.props.setLoading(true);
    this.productsService.getDocument(correspondence.documentId, correspondence.documentSource, correspondence.documentCategory).subscribe((res) => {
      this.props.setLoading(false);
      if (res.status === 200) {
        if (res.data.document) {
          this.updateDocError(false, docList, correspondence.documentId);
          this.convertToPDF(res.data.document);
        } else {
          !isIEBrowser && this.windowRef.close();
          this.updateDocError(true, docList, correspondence.documentId);
        }
      } else {
        !isIEBrowser && this.windowRef.close();
        this.updateDocError(true, docList, correspondence.documentId);
      }
    });
  };

  updateDocError = (flag, type, docId) => {
    if (type === 'PRODUCT_DOCS') {
      let productDocs = this.state.productDocuments
      let selectedDoc = productDocs.find((doc) => doc.documentId === docId);
      selectedDoc.showDocError = flag;
      this.setState({
        productDocuments: productDocs
      })
    } else {
      let productDocs = this.state.correspondences
      let selectedDoc = productDocs.find((doc) => doc.documentId === docId);
      selectedDoc.showDocError = flag;
      this.setState({
        correspondences: productDocs
      })
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.PRODUCT_DETAILS] || this.state.pageTitle}</title>
        </Helmet>
        <div className='innerBodyWrapper'>
          {this.props.notificationContext.state.infoNotification
            ? this.props.notificationContext.state.infoNotification.map((notification, index) => <Notification key={index} id={index} message={notification} />)
            : ""}
          <div className='productsDesc'>
            {this.props.notificationContext.state.nonInfoNotificaiton.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
            {this.state.dataLoaded && (
              <>
                {this.props.globalContext.state.deviceWidth >= 768 && (
                  <ProductDetailsDesktop
                    //
                    {...this.props}
                    optionalRiders={this.state.optionalRiders}
                    productOverview={this.state.productOverview}
                    additionalInsured={this.state.additionalInsured}
                    productDocuments={this.state.productDocuments}
                    correspondences={this.state.correspondences}
                    globlWebContents={this.state.globlWebContents}
                    openDocument={this.viewDocument}
                  />
                )}
                {this.props.globalContext.state.deviceWidth <= 767 && (
                  <ProductDetailsMobile
                    //
                    {...this.props}
                    optionalRiders={this.state.optionalRiders}
                    productOverview={this.state.productOverview}
                    additionalInsured={this.state.additionalInsured}
                    productDocuments={this.state.productDocuments}
                    correspondences={this.state.correspondences}
                    globlWebContents={this.state.globlWebContents}
                    openDocument={this.viewDocument}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
