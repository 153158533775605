import React from "react";
import { Link } from "react-router-dom";
import { ProfileService } from "../../../../_services/profile.service";
import { ChangePassword } from "../change-password-modal/ChangePassword";
import { Notification } from "../../../shared/notifications/notifications";
import { ChangeContactInfo } from "../change-contact-info/ChangeContactInfo";
import { ChangeSecurityQuestionPopup } from "../change-security-question/ChangeSecurityQuestion";
import { phoneFormatting } from "../../../../_services/utility.service";
import { SharedService } from "../../../../_services/shared.service";
import "./MyProfile.scss";
import { ARTICLES, PAGE_TITLES, PAGES_TITLE_KEYS } from "../../../../_constants/constants";
import { Helmet } from "react-helmet";

export class MyProfile extends React.Component {
  profileService = new ProfileService();
  sharedService = new SharedService();
  user = JSON.parse(localStorage.getItem("user"));

  state = {
    userProfile: null,
    questions: [],
    states: [],
    globlWebContents: {},
    profileDataLoaded: false,

    allowChangeContactInfo: false,
    allowChangePassword: false,
    allowChangeQnA: false,

    showChangePasswordPopup: false,
    showChangeContactInfoPopUp: false,
    showChangeSecurityQuestionPopup: false,
    pageTitle: ""
  };

  componentDidMount() {

    let { client, clientName } = JSON.parse(localStorage.getItem("initialIds"));
    this.setState({ pageTitle: clientName + PAGE_TITLES.PROFILE })

    this.getProfile();
    this.getSecurityQuestions();
    this.globalWC_getContent([
      ARTICLES.MYPROFILE_TIP
    ]);
  }

  globalWC_getContent = titles => {
    let { globalGroupId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      globalGroupId: globalGroupId,
      domainGroupId: domainGroupId,
      titles: titles
    };

    this.sharedService.getContents(params).subscribe(response => {
      if (response.status === 200) {
        let webContents = {};
        response.data.items.forEach(element => {
          webContents[element.title] = element.content;
        });
        this.setState({
          globlWebContents: webContents
        });
        // document.getElementById("globalWC_headerPhoneInfo").innerHTML = response.data.content;
      }
    });
  };

  getSecurityQuestions = () => {
    this.sharedService.getSecurityQuestions().subscribe(response => {
      if (response.status === 200) {
        this.setState({
          questions: response.data.items
        });
      } else {
        this.props.notificationContext.processMessages(response.data.messages);
      }
    });
  };

  getProfile = () => {
    let { companyId, client } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      companyId: companyId,
      client: client,
      accountNumber: this.user.accountNumber,
      policyNumber: this.user.policyNumber,
      policyHolder: {
        firstName: this.user.firstName,
        lastName: this.user.lastName
      },
      userid: this.user.userid,
      isEmailSubscribed: this.user.isEmailSubscribed,
      // userName: this.user.userid,
    };

    this.props.setLoading(true);
    this.profileService.getProfile(params).subscribe(response => {
      this.props.setLoading(false);
      if (response.status === 200) {
        this.setState({
          userProfile: response.data
        });
      }
      this.props.notificationContext.processMessages(response.data.messages);
    });
  };

  handleContactInfoPopupClose = data => {
    this.setState({ showChangeContactInfoPopUp: false });

    if (data) {
      this.props.notificationContext.processMessages(data.messages);
      this.setState(
        {
          userProfile: data.userProfile
        }
      );
    }
  };

  handleChangePasswordClose = data => {
    this.setState({ showChangePasswordPopup: false });

    if (data) {
      this.props.notificationContext.processMessages(data.messages);
    }
  };

  handleSecurityQuestionClose = data => {
    this.setState({ showChangeSecurityQuestionPopup: false });

    if (data) {
      let userProfile = this.state.userProfile;
      userProfile.securityQuestion = data.selectedQuestion;
      // this.getProfile();
      this.props.notificationContext.processMessages(data.messages);
      this.setState(
        {
          userProfile: userProfile
        }
      );
    }
  };

  handleResubsribe = (e) => {
    e.preventDefault();
    if (!this.props.globalContext.isCustomerServiceUser()) {
      this.props.setLoading(true);
      this.sharedService.resubscribe(this.state.userProfile.userId).subscribe(response => {
        if (response.status === 200) {
          let userProfile = this.state.userProfile;
          userProfile.emailUnsubscribedStatus = false;
          this.setState({ userProfile: userProfile });
        }
        this.props.setLoading(false);
        this.props.notificationContext.processMessages(response.data.messages);
      });
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.PROFILE] || this.state.pageTitle}</title>
        </Helmet>
        <div className='innerBodyWrapper'>
          {this.props.notificationContext.state.infoNotification
            ? this.props.notificationContext.state.infoNotification.map((notification, index) => <Notification key={index} id={index} message={notification} />)
            : ""}

          <div className='float-left innerContent myProfile'>
            <div className='myProfileContent'>
              <h1 className='mainHeadingT'>Profile</h1>
              {this.props.notificationContext.state.nonInfoNotificaiton.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
              {/* {this.state.infoMessage.type !== "" ? <div className={"p-2 mb-3 " + this.state.infoMessage.type}>{this.state.infoMessage.text}</div> : null} */}
              {this.state.userProfile &&
                <>
                  {this.state.showChangePasswordPopup && (
                    <ChangePassword
                      show={this.state.showChangePasswordPopup}
                      hide={this.handleChangePasswordClose}
                      {...this.props}
                    />
                  )}
                  {this.state.showChangeContactInfoPopUp && (
                    <ChangeContactInfo
                      states={this.state.states}
                      userProfile={this.state.userProfile}
                      show={this.state.showChangeContactInfoPopUp}
                      hide={this.handleContactInfoPopupClose}
                      {...this.props}
                    />
                  )}
                  {this.state.showChangeSecurityQuestionPopup && (
                    <ChangeSecurityQuestionPopup
                      selectedQuestion={this.state.userProfile.securityQuestion}
                      questions={this.state.questions}
                      show={this.state.showChangeSecurityQuestionPopup}
                      hide={this.handleSecurityQuestionClose}
                      {...this.props}
                    />
                  )}
                  <div className='customerInfo'>
                    <div className='profileName'>
                      <label>Full Name</label>
                      <label>
                        {this.state.userProfile.policyHolder.firstName} {this.state.userProfile.policyHolder.lastName}
                      </label>
                    </div>
                    <div className='dob'>
                      <label>Date of Birth</label>
                      <label>{this.state.userProfile.policyHolder.dateOfBirth}</label>
                    </div>
                  </div>
                  <div className='flexWhiteBox'>
                    <div className='leftColumn'>
                      <div className='headerColumn'>
                        <h3>Contact Information</h3>
                        <div
                          onClick={() => {
                            this.state.userProfile.editOption.save.disable === 'N' && this.setState({ showChangeContactInfoPopUp: true });
                          }}
                          className={'btn-links ' + (this.state.userProfile.editOption.save.disable === 'Y' ? 'disabled-link-btn' : '')}>
                          Make Changes
                  </div>
                      </div>
                      <div className='bodyRow'>
                        <div className='flexBox'>
                          <ul>
                            <li>
                              <span className='label'>ADDRESS</span>
                              <span className='value'>
                                <span title={this.state.userProfile.address.addressLine1}>{this.state.userProfile.address.addressLine1}</span>
                                {this.state.userProfile.address.addressLine2 && (
                                  <span title={this.state.userProfile.address.addressLine2}>{this.state.userProfile.address.addressLine2}</span>
                                )}
                                {this.state.userProfile.address.addressLine3 && (
                                  <span title={this.state.userProfile.address.addressLine3}>{this.state.userProfile.address.addressLine3}</span>
                                )}
                                <span
                                  title={this.state.userProfile.address.cityName + " " + this.state.userProfile.address.stateCode + " " + this.state.userProfile.address.zipCode}
                                  className='single-line-ellipses'>
                                  {this.state.userProfile.address.cityName}, {this.state.userProfile.address.stateCode} {this.state.userProfile.address.zipCode}
                                </span>
                              </span>
                            </li>
                            <li>
                              <span className='label'>PHONE</span> <span className='value'>{phoneFormatting(this.state.userProfile.phoneNumber)}</span>
                            </li>
                            <li>
                              <span className='label'>EMAIL</span>
                              <span className='value'>
                                <span title={this.state.userProfile.policyHolder.emailAddress} className='single-line-ellipses'>
                                  {this.state.userProfile.policyHolder.emailAddress}
                                </span>
                              </span>
                            </li>
                            {this.state.userProfile.emailUnsubscribedStatus &&
                              <li>
                                <span className='label'>TRANSACTION EMAILS</span>
                                <span className='value'>
                                  <span title={this.state.userProfile.policyHolder.emailAddress} className='single-line-ellipses'>
                                    <b>Unsubscribed</b>&nbsp;&nbsp;&nbsp;
                                <a
                                      href="#/"
                                      onClick={e => { this.handleResubsribe(e) }}
                                      className={'resubscribe-link ' + (this.props.globalContext.isCustomerServiceUser() ? 'disabled-link-btn' : '')}
                                    >Resubscribe</a>
                                  </span>
                                </span>
                              </li>
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='rightColumn'>
                      <div className='headerColumn'>
                        <h3>Security</h3>
                      </div>
                      <div className='bodyRow'>
                        <div className='flexBox'>
                          <ul>
                            <li>
                              <span className='label'>PASSWORD</span>
                              <span className='value'>
                                <span>***********</span>
                                <span>
                                  <Link
                                    title={""}
                                    to='/#'
                                    onClick={e => {
                                      e.preventDefault();
                                      this.state.userProfile.editOption.save.disable === 'N' && this.setState({
                                        showChangePasswordPopup: true
                                      });
                                    }}
                                    className={'btn-links ' + (this.state.userProfile.editOption.save.disable === 'Y' ? 'disabled-link-btn' : '')}>
                                    Change Password
                            </Link>
                                </span>
                              </span>
                            </li>
                            <li>
                              <span className='label'>SECURITY Q&A</span>
                              <span className='value'>
                                <span>{this.state.userProfile.securityQuestion}</span>
                                <span>
                                  <Link
                                    title={""}
                                    to='/#'
                                    onClick={e => {
                                      e.preventDefault();
                                      this.state.userProfile.editOption.save.disable === 'N' && this.setState({
                                        showChangeSecurityQuestionPopup: true
                                      });
                                    }}
                                    className={'btn-links ' + (this.state.userProfile.editOption.save.disable === 'Y' ? 'disabled-link-btn' : '')}>
                                    Change
                            </Link>
                                </span>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='footerTextPara mt-4' dangerouslySetInnerHTML={{ __html: this.state.globlWebContents[ARTICLES.MYPROFILE_TIP] }}></div>
                </>}


            </div>
          </div>
        </div>
      </>
    );
  }
}
