import React from "react";
import { Link } from "react-router-dom";
import { UpdatePaymentMethods } from "../../../shared/modals/update-payment-method/UpdatePaymentMethod";
import { DeletePaymentMethod } from "../../../shared/modals/delete-payment-method/DeletePaymentMethod";
import { PaymentCenterService } from "../../../../_services/payment-center.service";
import { SharedService } from "../../../../_services/shared.service";
import { getCardYears } from "../../../../_services/utility.service";
import { Notification } from "../../../shared/notifications/notifications";
import "./PaymentMethods.scss";
import { ARTICLES, PAGE_TITLES, PAGES_TITLE_KEYS } from '../../../../_constants/constants';
import { Helmet } from "react-helmet";

export class PaymentMethods extends React.Component {
  paymentCenterService = new PaymentCenterService();
  sharedService = new SharedService();

  state = {
    paymentMethods: [],
    states: [],
    years: getCardYears(),
    globlWebContents: {},

    showPaymentMethodPop: false,
    showDeletePopup: false,
    pageTitle: ""
  };

  componentDidMount() {
    let { client, clientName } = JSON.parse(localStorage.getItem("initialIds"));
    this.setState({ pageTitle: clientName + PAGE_TITLES.PAYMENT_METHODS })

    this.getPaymentMethods();
    this.globalWC_getContent([ARTICLES.CONFIRM_DELETE_PAYMENT_METHOD]);
  }

  globalWC_getContent = titles => {
    let { globalGroupId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      globalGroupId: globalGroupId,
      domainGroupId: domainGroupId,
      titles: titles
    };

    this.sharedService.getContents(params).subscribe(response => {
      if (response.status === 200) {
        let webContents = {};
        response.data.items && response.data.items.forEach(element => {
          webContents[element.title] = element.content;
        });
        this.setState({
          globlWebContents: webContents
        });
        // document.getElementById("globalWC_headerPhoneInfo").innerHTML = response.data.content;
      }
    });
  };


  getPaymentMethods = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let { companyId, client } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      client: client,
      accountNumber: user.accountNumber,
      policyNumber: user.policyNumber,
      firstName: user.firstName,
      lastName: user.lastName,
      userid: user.userid,
      isEmailSubscribed: user?.isEmailSubscribed,
      companyId: companyId
    };

    this.props.setLoading(true);
    this.paymentCenterService.getPaymentMethods(params).subscribe(response => {
      this.props.setLoading(false);
      if (response.status === 200) {
        this.setState({
          paymentMethods: response.data.paymentMethods || []
        });
      } else {
        this.setState({
          paymentMethods: [],
        });
        this.props.notificationContext.processMessages(response.data.messages);
      }
      this.props.notificationContext.processMessages(response.data.messages);
    });
  };

  handlePaymentMethodClose = (data, messages) => {
    this.setState({ showPaymentMethodPop: false });

    if (data) {
      this.props.notificationContext.processMessages(messages);
      this.setState(
        {
          paymentMethods: data
        },
        () => {
          window.scrollTo(0, 0);
        }
      );
    }
  };

  handleConfirmDeleteClose = (data, messages) => {
    this.setState({ showDeletePopup: false });

    if (data) {
      this.props.notificationContext.processMessages(messages);
      this.setState({ paymentMethods: data });
      // this.getPaymentMethods();
    } else if (data === null) {
      // Response failed from server ie status !== 200
      this.props.notificationContext.processMessages(messages);
    }
  };

  addNewPaymentMethod = () => {
    this.setState({
      selectedPaymentMethod: null,
      showPaymentMethodPop: true
    });
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.PAYMENT_METHODS] || this.state.pageTitle}</title>
        </Helmet>
        <div className=''>
          {this.state.showPaymentMethodPop && (
            <UpdatePaymentMethods
              states={this.state.states}
              years={this.state.years}
              selectedPaymentMethod={this.state.selectedPaymentMethod}
              show={this.state.showPaymentMethodPop}
              hide={this.handlePaymentMethodClose}
              // setLoading={this.props.setLoading}
              {...this.props}
            />
          )}
          {this.state.showDeletePopup && (
            <DeletePaymentMethod
              {...this.props}
              content={this.state.globlWebContents[ARTICLES.CONFIRM_DELETE_PAYMENT_METHOD]}
              selectedPaymentMethod={this.state.selectedPaymentMethod}
              show={this.state.showDeletePopup}
              hide={this.handleConfirmDeleteClose}
              setLoading={this.props.setLoading}
            />
          )}
          <div>
            {this.props.notificationContext.state.nonInfoNotificaiton.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
            <div className='paymentMWrapper'>
              <div className='flexWhiteBox'>
                {this.state.paymentMethods.map(value => (
                  <div key={value.paymentRecordId} className='leftColumn'>
                    <div className='headerColumn'>
                      <h3>{value.paymentMethodHeader}</h3>
                      <div className='headerButton'>
                        <Link
                          title={""}
                          to='/#'
                          onClick={e => {
                            e.preventDefault();
                            value.managePaymentMethodOption.deletePaymentMethod.disable === 'N' && this.setState({
                              selectedPaymentMethod: value,
                              showDeletePopup: true
                            });
                          }}>
                          <span className={value.managePaymentMethodOption.deletePaymentMethod.disable === 'Y' ? 'disabled-link-btn' : ''}>Delete</span>
                        </Link>
                        <Link
                          title={""}
                          to='/#'
                          onClick={e => {
                            e.preventDefault();
                            value.managePaymentMethodOption.editPaymentMethod.disable === 'N' && this.setState({
                              selectedPaymentMethod: value,
                              showPaymentMethodPop: true
                            });
                          }}>
                          <span className={value.managePaymentMethodOption.editPaymentMethod.disable === 'Y' ? 'disabled-link-btn' : ''}>Edit</span>
                        </Link>
                      </div>
                    </div>
                    {value.bankAccount == null && (
                      <div className='bodyRow'>
                        <div className='flexBox'>
                          <ul>
                            <li>
                              <span className='label'>PAYMENT TYPE</span>
                              <span className='value'>{value.paymentMethodHeader}</span>
                            </li>
                            <li>
                              <span className='label'>CARDHOLDER NAME</span>
                              <span className='value single-line-ellipses' title={value.cardHolderName}>
                                {value.cardHolderName}
                              </span>
                            </li>
                            <li>
                              <span className='label'>CARD NUMBER</span>
                              <span className='value'>{value.creditCard.cardNumber}</span>
                            </li>
                            <li>
                              <span className='label'>CARD EXPIRATION</span>
                              <span className='value'>{value.creditCard.expirationDate}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}

                    {value.creditCard == null && (
                      <div className='bodyRow'>
                        <div className='flexBox'>
                          <ul>
                            <li>
                              <span className='label'>PAYMENT TYPE</span>
                              <span className='value'>{value.paymentMethodHeader}</span>
                            </li>
                            <li>
                              <span className='label'>ACCOUNT-HOLDER NAME</span>
                              <span className='value'>{value.cardHolderName}</span>
                            </li>
                            <li>
                              <span className='label'>ACCOUNT NUMBER</span>
                              <span className='value'>{value.bankAccount.accountNumber}</span>
                            </li>
                            <li>
                              <span className='label'>ROUTING NUMBER</span>
                              <span className='value'>{value.bankAccount.maskedRoutingNumber}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                ))}

                <div className='addPaymentM leftColumn dotted-spaced'>
                  <Link
                    title={""}
                    className='addPaymentButton'
                    to='/#'
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        selectedPaymentMethod: null,
                        showPaymentMethodPop: true
                      });
                    }}>
                    <div className='materialicons'>add</div>
                    <div className='navText'>Add Payment Method</div>
                  </Link>
                </div>

              </div>
            </div>
            {/* <div className='flexWhiteBox addPaymentM col-lg-6 col-md-12 float-left p-0'>
            <div className='leftColumn dotted-spaced col-lg-12 col-md-12 col-xs-12 col-12 float-left p-0'>
              <Link title={""}
                className='addPaymentButton'
                to='/#'
                onClick={e => {
                  e.preventDefault();
                  this.setState({
                    selectedPaymentMethod: null,
                    showPaymentMethodPop: true
                  });
                }}>
                <div className='materialicons'>add</div>
                <div className='navText'>Add Payment Method</div>
              </Link>
            </div>
          </div> */}
            {/* <div className='flexWhiteBox col-lg-12 col-md-12 float-left p-0'>
            <div className='rightColumn col-lg-6 col-md-12 col-xs-12 col-12 float-left p-0'>
              <div className='headerColumn'>
                <h5>Mastercard-1234</h5>
                <div className='headerButton'>
                  <a
                    onClick={() => {
                      this.setState({ showDeletePopup: true });
                    }}>
                    {" "}
                    Delete
                  </a>
                  <a>Edit</a>
                </div>
              </div>
              <div className='bodyRow'>
                <div className='flexBox'>
                  <ol>
                    <li>PAYMENT TYPE</li>
                    <li>Visa</li>
                    <li>CARDHOLDER NAME</li>
                    <li>John Smith</li>
                    <li>CARD NUMBER</li>
                    <li>XXXX-XXXX-XXXX-1234</li>
                    <li>CARD EXPIRATION</li>
                    <li>01/22</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className='flexWhiteBox col-lg-12 col-md-12 float-left p-0'>
            <div className='leftColumn col-lg-6 col-md-12 col-xs-12 col-12 float-left p-0'>
              <div className='headerColumn'>
                <h5>Checking-1234</h5>
                <div className='headerButton'>
                  <a to='#'>Delete</a>
                  <a to='#'>Edit</a>
                </div>
              </div>
              <div className='bodyRow'>
                <div className='flexBox'>
                  <ol>
                    <li>PAYMENT TYPE</li>
                    <li>Checking</li>
                    <li>CARDHOLDER NAME</li>
                    <li>John Smith</li>
                    <li>ACCOUNT NUMBER</li>
                    <li>XXXX-1234</li>
                    <li>ROUTING NUMBER</li>
                    <li>XXXX-XX-2334</li>
                  </ol>
                </div>
              </div>
            </div>
            <div className='rightColumn col-lg-6 col-md-12 col-xs-12 col-12 float-left empty p-0'></div>
          </div> */}
          </div>
        </div>
      </>
    );
  }
}
